import {
    NavLink,
    Outlet,
  } from "react-router-dom";  
import { useState, useEffect } from 'react';

export default function Layout(){
    const [time, setTime] = useState("dusk");

    useEffect(() => {
        const elements = document.querySelectorAll('.off');
        let interval = 890;
        elements.forEach((element, index) => {
          setTimeout(function () {
            element.classList.remove('off');
          }, index * interval);
        });
      }, []);

    function openOption(e){
      document.querySelectorAll('.link-message').forEach(x=>x.classList.remove('active'))
      e.target.classList.add('active');
    
      var type = e.target.dataset.option
      console.log(type);
      console.log("changing time to "+type)
      setTimeout(function() {
        setTime(type)
      }, 300);
    }
  
    return (
      <div className="App">
        <div id="controls" className="container-fluid min-vh-100 d-flex flex-column">
          <div className="row flex-grow-1">
            <div id="contentContainer" className="col-12 col-md">
              <Outlet />
            </div>
            <div className="col-12 col-md-3 border border-light-subtle">
              <div className="d-flex flex-column align-items-end h-100">
                <div className="main-content">
                  <div className="window">
                    <div className="illustration">
                      <div id="time-container" className={`time ${time}`}>
                        <div className="sky">
                          <svg viewBox="0 0 113.39 99.21" width="100%"><linearGradient gradientTransform="rotate(-25)" gradientUnits="userSpaceOnUse" id="sky_sunset" x1="0%" x2="100%" y1="0%" y2="0%"><stop offset="0%" stopColor="#f7ffa9" stopOpacity="1"></stop><stop offset="40%" stopColor="#ffcc33" stopOpacity="1"></stop><stop offset="100%" stopColor="#ffbe1d" stopOpacity="1"></stop></linearGradient><linearGradient gradientTransform="rotate(45)" gradientUnits="userSpaceOnUse" id="sky_dusk" x1="0%" x2="100%" y1="0%" y2="0%"><stop offset="0%" stopColor="#ffd4fe" stopOpacity="1"></stop><stop offset="30%" stopColor="#ffd4fe" stopOpacity="1"></stop><stop offset="100%" stopColor="#ffd26a" stopOpacity="1"></stop></linearGradient><polygon fill="#B3EFFF" id="sky" points="111.971,97.368 111.971,1.842 1.419,1.842 1.419,9.042 1.419,97.368"></polygon></svg>
                        </div>
                        <div className="sun">
                          <svg viewBox="0 0 90.71 90.71" width="36%"><g><circle cx="45.355" cy="45.354" fill="#FFECDC" opacity="0.1" r="43.212"></circle><circle cx="45.355" cy="45.354" fill="#FFEBDE" opacity="0.3" r="31.131"></circle><circle cx="45.355" cy="45.354" fill="#f9db5a" id="sun" r="21.374"></circle></g></svg>
                        </div>
                        <div className="clouds">
                          <svg viewBox="0 0 42.52 19.84" width="32%"><g><path d="M38.582,8.005c1.865,0,3.393,1.673,3.393,3.717l0,0c0,2.042-1.527,3.716-3.393,3.716H24.039h-0.484   c-2.676,0-4.847-2.378-4.847-5.309c0-2.932,2.17-5.309,4.847-5.309c0,0,2.242-4.247,7.756-4.247c3.453,0,6.787,1.925,6.787,7.432   H38.582z" fill="#fff"></path><path d="M20.863,11.667c1.908,0,3.471,1.71,3.471,3.8l0,0c0,2.09-1.563,3.8-3.471,3.8H5.998H5.501   c-2.736,0-4.956-2.431-4.956-5.428s2.219-5.428,4.956-5.428c0,0,2.292-4.342,7.929-4.342c3.532,0,6.939,1.968,6.939,7.598H20.863z" fill="#FCFCFC"></path></g></svg>
                        </div>
                        <div className="stars">
                          <svg viewBox="0 0 99.21 25.51" width="88%"><g><ellipse cx="97.067" cy="11.361" fill="#FFFFFF" opacity="0.3" rx="0.727" ry="0.832"></ellipse><ellipse cx="2.144" cy="3.901" fill="#FFFFFF" opacity="0.3" rx="0.728" ry="0.831"></ellipse><ellipse cx="14.743" cy="10.455" fill="#FFFFFF" opacity="0.3" rx="0.727" ry="0.831"></ellipse><ellipse cx="66.439" cy="9.407" fill="#FFFFFF" opacity="0.3" rx="0.728" ry="0.831"></ellipse><ellipse cx="77.518" cy="1.332" fill="#FFFFFF" opacity="0.3" rx="0.728" ry="0.831"></ellipse><ellipse cx="82.514" cy="13.459" fill="#FFFFFF" opacity="0.3" rx="0.728" ry="0.831"></ellipse></g></svg>
                        </div>
                        <div className="moon">
                          <svg viewBox="0 0 15.59 19.28" width="15%"><path d="M13.718,16.993c-5.085,0-9.208-4.122-9.208-9.208c0-2.97,1.407-5.608,3.589-7.292  C3.722,1.205,0.381,5,0.381,9.579c0,5.086,4.123,9.208,9.209,9.208c2.116,0,4.063-0.716,5.619-1.916  C14.722,16.95,14.226,16.993,13.718,16.993z" fill="#FFFFFF" opacity="0.8"></path></svg>
                        </div>
                        <div className="mountain">
                          <svg viewBox="0 0 113.39 36.85" width="100%"><path d="M111.971,0.478C106.576,2.7,95.837,16.269,88.655,15C81.29,13.699,68.052,6.244,62.31,3.639  C56.566,1.036,41.955,16.42,36.835,15.948c-5.118-0.474-17.355-9.232-23.475-8.403c-3.964,0.537-8.932,4.276-11.941,6.844v21.983  h110.552V0.478z" fill="#87D6D6" id="mountain"></path></svg>
                        </div>
                        <div className="hill">
                          <svg viewBox="0 0 113.39 17.86" width="100%"><path d="M111.971,12.132c-4.609-0.766-8.889-1.889-12.432-3.579C86.127-0.36,71.48-3.606,43.082,6.31  c-6.086,0.811-7.853,0.51-17.234-2.951C18.487,0.644,8.764,1.176,1.419,2.621v15.017h110.551V12.132z" fill="#38C6B1" id="hill"></path></svg>
                        </div>
                        <div className="land">
                          <svg viewBox="0 0 113.39 24.09" width="100%"><path d="M111.971,23.82V3.328C84.865-6.96,12.407,11.765,1.419,14.708v9.112H111.971z" fill="#4CB5AB" id="land"></path></svg>
                        </div>
                        <div className="trees">
                          <svg viewBox="0 0 85.04 41.1" width="88%"><g><rect className="trunks" fill="#8C5F50" height="8.666" width="2.166" x="75.121" y="26.724"></rect><rect className="trunks" fill="#8C5F50" height="6.5" width="2.168" x="67.539" y="28.89"></rect></g><g><path className="trees" d="M83.785,21.309c0,4.187-3.393,7.581-7.58,7.581s-7.582-3.395-7.582-7.581     c0-9.749,3.395-20.579,7.582-20.579S83.785,11.56,83.785,21.309z" fill="#A0D755"></path><path className="trees" d="M75.121,24.933c0,3.382-2.91,6.125-6.5,6.125c-3.588,0-6.498-2.743-6.498-6.125     c0-7.873,2.91-16.622,6.498-16.622C72.211,8.311,75.121,17.06,75.121,24.933z" fill="#A0D755"></path></g><g opacity="0.2"><path d="M72.955,21.309c0-8.892,1.883-18.68,4.332-20.333c-0.354-0.16-0.715-0.247-1.082-0.247     c-4.188,0-7.582,10.83-7.582,20.579c0,4.187,3.395,7.581,7.582,7.581c0.369,0,0.729-0.035,1.082-0.086     C74.84,28.277,72.955,25.127,72.955,21.309z" fill="#FFFFFF"></path><path d="M66.457,24.933c0-7.106,1.383-14.901,3.191-16.359c-0.334-0.157-0.676-0.263-1.025-0.263     c-3.588,0-6.5,8.749-6.5,16.622c0,3.382,2.912,6.125,6.5,6.125c0.35,0,0.691-0.034,1.025-0.084     C67.84,30.508,66.457,27.984,66.457,24.933z" fill="#FFFFFF"></path></g><g><rect className="trunks" fill="#8C5F50" height="4.424" width="1.475" x="3.466" y="35.946"></rect><path className="trees" d="M7.153,34.472c0,1.628-1.32,2.949-2.949,2.949S1.254,36.1,1.254,34.472c0-5.162,1.32-11.799,2.949-11.799     S7.153,29.31,7.153,34.472z" fill="#A0D755"></path><path d="M3.466,34.472c0-4.233,0.666-9.452,1.58-11.194c-0.267-0.383-0.55-0.604-0.842-0.604    c-1.629,0-2.949,6.637-2.949,11.799c0,1.628,1.32,2.949,2.949,2.949c0.295,0,0.573-0.057,0.842-0.137    C4.135,36.919,3.466,35.806,3.466,34.472z" fill="#FCFCFC" opacity="0.2"></path></g></svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src="img/page-chris-prof.jpeg" className="prof rounded" alt="logo" width="80px"/>
                </div>
              
                <div id="messages" className="mt-auto mb-2">
                  <div className="">
                    <div className="chris-message off">Hi there, I'm Chris and welcome to my page</div>
                    <div className="chris-message off">What brings you here?</div>
                  </div>
                  <div id="links" className="text-end off">
                    <NavLink className="link-message" to={``} onClick={(e) => openOption(e)} data-option="day" id="nav-home-tab">
                      Just saying hi
                    </NavLink>
                    <NavLink className="link-message" to={`https://upliftduo.com`} onClick={(e) => openOption(e)} data-option="day" id="nav-projects-tab">
                      Learn about your projects
                    </NavLink>
                    <NavLink className="link-message" to='https://goo.gl/maps/aNDC2eyTzDCQh4kc7' data-option="sunset" id="nav-profile-tab">
                      Good food recomendations
                    </NavLink>
                    <NavLink className="link-message" to={`tennis`} onClick={(e) => openOption(e)} data-option="dusk"  id="nav-tennis-tab">
                      Let's play tennis
                    </NavLink>                                
                    <NavLink className="link-message" to={`connect`} onClick={(e) => openOption(e)} data-option="night"  id="nav-contact-tab">
                      Schedule a time to connect
                    </NavLink>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
  
  
        </div>
      </div>
    )
  }
  