import { useState, useRef, useEffect } from "react";

export default function Tennis() {
  const ref = useRef();
  const [height, setHeight] = useState('0px');

  const onLoad = () => {
    let elHeight = (document.getElementById("contentContainer").clientHeight > 600) ? document.getElementById("contentContainer").clientHeight - 10 : 600;
    setHeight(elHeight + 'px')  };
    useEffect(() => {
      onLoad();
    }, []);

  return (
    <div className="">
      <iframe 
        title="Google Calendar Booking"
        ref={ref}
        onLoad={onLoad}
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ31_v5CEbfVsfDC1MpSVnG8l66JFUDR2_gO1xsNHq_o9nWlNcrxccquPUeEkgodyAP9D1SWdjPR?gv=true" 
        width="100%" 
        height={height}
        frameBorder="0"
      ></iframe>
  </div>
  )
}

