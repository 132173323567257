import { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    const elements = document.querySelectorAll('.off');
    let interval = 890;
    elements.forEach((element, index) => {
      setTimeout(function () {
        element.classList.remove('off');
      }, index * interval);
    });
  }, []);

  return (
    <>
      <div id="homeContainer" className="d-flex h-100">
        <div className="page-icons mt-auto">
          <a href="https://instagram.com/chrisuniversity"><img width="30px" className="page-icon" src="/icons/instagram-fill.svg" alt="Instagram"></img></a>
          <a href="https://www.linkedin.com/in/chrisuniversity"><img width="30px" className="page-icon" src="/icons/linkedin-fill.svg" alt="LinkedIn"></img></a>
          <a href="https://github.com/chris920"><img width="30px" className="page-icon" src="/icons/github-fill.svg" alt="Github"></img></a>
          <a href="mailto:chris@dougliu.com"><img width="30px" className="page-icon" src="/icons/mail-16.svg" alt="Email"></img></a>
        </div>
      </div>
    </>
  )
}

