import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Home from "./pages/home/index"
import Projects from "./pages/projects/index"
import Social from "./pages/social/index"
import Tennis from "./pages/tennis/index"
import Connect from "./pages/connect/index"
import ErrorPage from "./pages/error-page"
import Layout from "./pages/layout"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },      
      {
        path: "Projects",
        element: <Projects />,
      },
      {
        path: "Social",
        element: <Social />,
      },
      {
        path: "Tennis",
        element: <Tennis />,
      },      
      {
        path: "Connect",
        element: <Connect />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

