import { useState, useRef, useEffect } from "react";

export default function Connect() {
  const ref = useRef();
  const [height, setHeight] = useState('0px');

  const onLoad = () => {
    let elHeight = (document.getElementById("contentContainer").clientHeight > 600) ? document.getElementById("contentContainer").clientHeight - 10 : 600;
    setHeight(elHeight + 'px')  };
    useEffect(() => {
      onLoad();
    }, []);

  return (
    <div className="">
      <iframe 
        title="Google Calendar Booking"
        ref={ref}
        onLoad={onLoad}
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1Li7SfjrR8v6h4H_YM6nl4pCInqCyQusVjocxGImyi6qbmX4tbwA7kHbV_v_uIFsGsnvVjz9HC?gv=true" 
        width="100%" 
        height={height}
        frameBorder="0"
      ></iframe>
  </div>
  )
}

