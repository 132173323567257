export default function Social() {
  return (
    <div className="d-grid gap-2">
        <div className="page-icons mt-auto">
          <a href="https://instagram.com/chrisuniversity" className="col btn btn-link m-2"><img width="30px" className="page-icon" src="/icons/instagram-fill.svg" alt="Instagram"></img></a>
          <a href="https://www.linkedin.com/in/chrisuniversity" className="col btn btn-link m-2"><img width="30px" className="page-icon" src="/icons/linkedin-fill.svg" alt="LinkedIn"></img></a>
          <a href="https://github.com/chris920" className="col btn btn-link m-2"><img width="30px" className="page-icon" src="/icons/github-fill.svg" alt="Github"></img></a>
          <a href="mailto:chris@dougliu.com" className="col btn btn-link m-2"><img width="30px" className="page-icon" src="/icons/mail-16.svg" alt="Email"></img></a>
        </div>
  </div>
  )
}

